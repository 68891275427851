import React, { useEffect, useState } from "react"
import Layout from "../Layout"
import { Link } from "gatsby"
import Seo from "../components/seo"

const Page404 = () => {
  const [ready, setReady] = useState(false)
  useEffect(() => {
    setReady(true)
  }, [])

  if (!ready) return null
  return (
    <Layout>
      <Seo title="404: Not found" noIndex />
      <div className="container text-center mt-5 pt-5">
        <h1>404: Page Not Found</h1>
        <p>You just hit a route that doesn&#39;t exist...</p>
        <a href={"https://store.owita.lk"} className={"btn btn-primary mx-2"}>
          Go to Shop
        </a>
        <Link to={"/"} className={"btn btn-primary mx-2"}>
          Return to Home
        </Link>
      </div>
    </Layout>
  )
}
export default Page404
